// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__AKPrN {\n  display: flex;\n  min-height: 40px;\n  align-items: center;\n  padding-bottom: 1rem;\n  padding-bottom: var(--padding, 1rem);\n}\n\n.styles_root__AKPrN > h1 {\n  flex-grow: 1;\n}\n\n.styles_breadCrumbs__TtuRC {\n  flex-grow: 0;\n  flex-shrink: 1;\n}\n\n.styles_base64LinkIcon__ZYfnW {\n  width: 12px;\n  height: 12px;\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoC;EAApC,oCAAoC;AACtC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".root {\n  display: flex;\n  min-height: 40px;\n  align-items: center;\n  padding-bottom: var(--padding, 1rem);\n}\n\n.root > h1 {\n  flex-grow: 1;\n}\n\n.breadCrumbs {\n  flex-grow: 0;\n  flex-shrink: 1;\n}\n\n.base64LinkIcon {\n  width: 12px;\n  height: 12px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__AKPrN",
	"breadCrumbs": "styles_breadCrumbs__TtuRC",
	"base64LinkIcon": "styles_base64LinkIcon__ZYfnW"
};
export default ___CSS_LOADER_EXPORT___;
