import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BreadCrumbItem } from "components/PageHeader";
import { isInsideShell } from "../utils/env";
import config from "../config";

interface BreadCrumbsConfig {
  contracts: BreadCrumbItem[];
  contract: BreadCrumbItem[];
}

const getBackupPath = () => (isInsideShell() ? "/backup" : config.baseUrl + "/backup");

const useBreadCrumbsConfig = (): BreadCrumbsConfig => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("");
  return useMemo(
    () => ({
      contracts: [
        { name: t("dm-common:Backup"), path: getBackupPath(), dustinIcon: "security-2" },
        { name: t("dm-common:Contracts") },
      ],
      contract: [
        { name: t("dm-common:Backup"), path: getBackupPath(), dustinIcon: "security-2" },
        { name: t("dm-common:Contracts"), path: "/backup/contracts" },
        { name: id },
      ],
    }),
    [t, id]
  );
};

export default useBreadCrumbsConfig;
