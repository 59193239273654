import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb, BreadcrumbItemProps, PageHeader as Header, Icon } from "@skyportal/ui-kit";
import { makeDocumentTitleFromBreadCrumbs } from "./utils";
import { isFullLink } from "../../utils/links";
import styles from "./styles.module.css";

export interface BreadCrumbItem {
  name: string;
  path?: string;
  faIcon?: BreadcrumbItemProps["icon"];
  dustinIcon?: string;
}

export interface PageHeaderProps {
  title: string;
  backUrl?: string;
  breadCrumbs: BreadCrumbItem[];
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, breadCrumbs, backUrl }) => {
  const [current, ...rest] = [...breadCrumbs].reverse();
  const history = useHistory();
  const handleBackClick = backUrl ? () => history.push(backUrl) : undefined;
  const documentTitle = makeDocumentTitleFromBreadCrumbs(breadCrumbs);

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Header
        title={title}
        onBackClick={handleBackClick}
        rightContent={
          <Breadcrumb data-testid="breadcrumbs">
            {[...rest]
              .reverse()
              .filter((item) => Boolean(item.path))
              .map((item, index) => (
                <Breadcrumb.Item icon={item.faIcon} key={index}>
                  {item.dustinIcon && <Icon icon={item.dustinIcon} className={styles.base64LinkIcon} />}
                  {isFullLink(item.path!) ? (
                    <a href={item.path} className={styles.link}>
                      {item.name}
                    </a>
                  ) : (
                    <Link to={item.path!} className={styles.link}>
                      {item.name}
                    </Link>
                  )}
                </Breadcrumb.Item>
              ))}
            <Breadcrumb.Item>{current.name}</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
    </>
  );
};

export default React.memo(PageHeader);
